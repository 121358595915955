import { Field, Mutation, Query } from '@tilework/opus';

import { NetworkError } from 'Type/Common.type';
import { GQLRegistrationForm } from 'Type/Graphql.registration.type';

/** @namespace Steinkrueger/Query/RegistrationForm/Query */
export class RegistrationFormQuery {
    getSendRegistrationFormMutation(options: GQLRegistrationForm): Mutation<'sendSignupRequest', NetworkError> {
        const mutation = new Mutation<'sendSignupRequest', NetworkError>('sendSignupRequest');
        const base64Encoded = {
            data: {
                file_data: '',
                file_name: '',
            },
        };

        if (options.file && options.file.base64_encoded_file) {
            base64Encoded.data.file_data = JSON.parse(options.file.base64_encoded_file).file_data.split(',')[1];
            base64Encoded.data.file_name = JSON.parse(options.file.base64_encoded_file).file_name;
        }

        const updatedOptions = {
            firstname: options.firstname,
            lastname: options.lastname,
            phone_number: options.phone_number,
            email: options.email,
            company: options.company,
            country: options.country,
            postcode: options.postcode,
            city: options.city,
            address: options.address,
            message: options.message,
            file: {
                name: base64Encoded.data.file_name,
                base64_encoded_file: base64Encoded.data.file_data,
            },
        };

        this._addSendRegistrationFormMutationArguments(mutation, updatedOptions);
        mutation.addFieldList(this._getSendRegistrationFormMutationResponse());

        return mutation;
    }

    getContactPageConfigQuery(): Query<'contactPageConfig', { enabled: boolean }> {
        return new Query<'contactPageConfig', { enabled: boolean }>('contactPageConfig')
            .addFieldList(this._getContactPageConfigFields());
    }

    _addSendRegistrationFormMutationArguments(
        mutation: Mutation<'sendSignupRequest', NetworkError>,
        options: GQLRegistrationForm,
    ): Mutation<'sendSignupRequest', NetworkError> {
        return mutation.addArgument('input', 'SignupRequestInput', options);
    }

    _getSendRegistrationFormMutationResponse(): Field<'__typename', string>[] {
        return [new Field<'__typename', string>('__typename')];
    }

    _getContactPageConfigFields(): Field<'enabled', boolean>[] {
        return [new Field<'enabled', boolean>('enabled')];
    }
}

export default new RegistrationFormQuery();
