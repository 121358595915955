import {
    FieldSelectContainer as SourceFieldSelectContainer,
} from 'SourceComponent/FieldSelect/FieldSelect.container';
import {Option} from 'Type/Field.type';

/** @namespace Steinkrueger/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    handleSelectListOptionClick(option: Option): void {
        const { changeValueOnDoubleClick, events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        if (!this.fieldRef) {
            return;
        }

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : String(fieldValue);
        } else {
            this.fieldRef.value = String(fieldValue);
        }

        if (onChange) {
            this.setState({ isExpanded: false });
            onChange(String(fieldValue));
        }
    }
}

export default FieldSelectContainer;
