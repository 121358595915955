import {FieldType} from 'Component/Field/Field.config';
import {FieldReactEvents} from 'Component/Field/Field.type';
import Loader from 'Component/Loader';
import {
    FieldFileComponent as SourceFieldFileComponent,
} from 'SourceComponent/FieldFile/FieldFile.component';
import {ReactElement} from 'Type/Common.type';

import './FieldFile.override.style';

/** @namespace Steinkrueger/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFileComponent {
    renderFileLabel(): ReactElement {
        const {
            attr: { id = '', multiple = false } = {},
            fileName = '',
            isLoading = false,
            resetFieldValue,
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (fileName) {
            return (
                <label htmlFor={ id }>
                    <span>{ fileName }</span>
                    <button block="Field" elem="SelectFileBtn" onClick={ resetFieldValue as any }>
                        { __('Remove file') }
                    </button>
                </label>
            );
        }

        const selectLabel = multiple ? __('Upload file') : __('Upload file');

        return (
            <label htmlFor={ id }>
                <span block="Field" elem="SelectFileBtn">{ selectLabel }</span>
                <span>
                    { __('No file selected') }
                </span>
            </label>
        );
    }

    render(): ReactElement {
        const {
            attr = {},
            attr: { accept = '' } = {},
            events = {},
            setRef,
        } = this.props;

        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <>
                <div block="FieldFile" elem="Wrapper">
                    <input
                      ref={ (elem) => setRef(elem) }
                      type={ FieldType.FILE }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...attr }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...events as FieldReactEvents<HTMLInputElement> }
                    />
                    { this.renderFileLabel() }
                    { allowedFieldTypes.length > 0 && this.renderSubLabel(allowedFieldTypes) }
                </div>
                <div block="FieldFile" elem="Description">
                    { __('The following formats are permitted: JPG, JPEG, PNG, PDF. Maximum file size: 2 MB.') }
                </div>
            </>
        );
    }
}

export default FieldFileComponent;
