import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import {ReactElement} from 'Type/Common.type';

import './Footer.override.style';

/** @namespace Steinkrueger/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    renderCmsBlockWrapper(): ReactElement {
        const footer_cms = 'footer_cms';

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }

    render(): ReactElement {
        const { isVisibleOnMobile, device } = this.props;
        const isBlog = location.pathname.includes('/blog');

        if (device.isMobile && isBlog) {
            return (
                <RenderWhenVisible>
                    <footer block="Footer" aria-label="Footer">
                        { this.renderContent() }
                        { this.renderCopyrightContent() }
                    </footer>
                </RenderWhenVisible>
            );
        }

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label="Footer">
                    { this.renderContent() }
                    { this.renderCopyrightContent() }
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
