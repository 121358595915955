import { Dispatch } from 'redux';

import RegistrationFormQuery from 'Query/RegistrationForm.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';

import { updateRegistrationForm } from './RegistrationForm.action';
import {RegistrationFormDispatcherOptions} from './RegistrationForm.type';

/**
 * ContactForm Dispatcher
 * @class RegistrationFormDispatcher
 * @extends QueryDispatcher
 * @namespace Steinkrueger/Store/RegistrationForm/Dispatcher */
export class RegistrationFormDispatcher {
    prepareRequest(options: RegistrationFormDispatcherOptions, dispatch: Dispatch): Promise<void> {
        const { form = {}, fields = {}} = options;

        const mutation = RegistrationFormQuery.getSendRegistrationFormMutation(fields);

        dispatch(updateRegistrationForm({
            isLoading: true,
        }));

        return fetchMutation(mutation)
            .then(
                /** @namespace Steinkrueger/Store/RegistrationForm/Dispatcher/RegistrationFormDispatcher/prepareRequest/fetchMutation/then */
                (data) => {
                    const defaultMessage = __('You sent request successfully');
                    // eslint-disable-next-line max-len
                    dispatch(showNotification(NotificationType.SUCCESS, data.sendSignupRequest.message || defaultMessage));
                    dispatch(updateRegistrationForm({
                        isLoading: false,
                    }));

                    // Clears form
                    if (typeof form.reset === 'function') {
                        form.reset();
                    }
                },
                /** @namespace Steinkrueger/Store/RegistrationForm/Dispatcher/RegistrationFormDispatcher/prepareRequest/fetchMutation/then/catch */
                (error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
                    dispatch(updateRegistrationForm({
                        isLoading: false,
                    }));
                },
            );
    }
}

export default new RegistrationFormDispatcher();
