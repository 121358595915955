import {SyntheticEvent} from 'react';

import {FieldType} from 'Component/Field/Field.config';
import {FieldContainerProps} from 'Component/Field/Field.type';
import {FieldContainer as SourceFieldContainer} from 'SourceComponent/Field/Field.container';
import {ValidationOutput} from 'Type/Field.type';
import {noopFn} from 'Util/Common';
import { validate } from 'Util/Validator';
import {FieldValidationOutput} from 'Util/Validator/Validator.type';

/** @namespace Steinkrueger/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static defaultProps: Partial<FieldContainerProps> = {
        type: FieldType.TEXT,
        attr: {},
        events: {},
        mix: {},
        validationRule: {},
        validateOn: [],
        options: [],
        showErrorAsLabel: true,
        isDisabled: false,
        addRequiredTag: false,
        label: '',
        subLabel: '',
        elemRef: undefined,
        changeValueOnDoubleClick: false,
        isSortSelect: false,
        updateSelectedValues: noopFn,
        isKg: false,
        priceType: '',
    };

    validate(data?: (Event | SyntheticEvent) & ValidationOutput): boolean | FieldValidationOutput {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        const value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef as HTMLInputElement)?.checked
            : this.fieldRef?.value;

        if (!value && value !== '' && value !== false) {
            return false;
        }

        const response = validate(type === FieldType.FILE
        && typeof value === 'string'
            ? value.toLowerCase()
            : value, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        return output;
    }

    validateOnEvent(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hook: (...args: any[]) => void,
        ...args: ((event?: SyntheticEvent) => void)[]
    ): void {
        if (hook && this.fieldRef) {
            const { attr, type } = this.props;
            const { value } = this.fieldRef;

            hook(...[...args, {
                ...attr, fieldRef: this.fieldRef, value, type,
            }]);
        }

        this.validate();
    }

    containerProps() {
        const {
            events,
            validateOn,
            type,
            attr,
            isDisabled,
            mix,
            value,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            isKg,
            priceType,
        } = this.props;
        const { validationResponse } = this.state;
        const { validate } = this.containerFunctions;

        // Surrounds events with validation
        const newEvents = { ...events };

        validateOn.forEach((eventName) => {
            const { [ eventName as keyof typeof events]: baseEvent } = events;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newEvents[ eventName ] = baseEvent
                ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            attr,
            value,
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            validationResponse,
            isKg,
            priceType,
            resetFieldValue: this.resetFieldValue.bind(this),
            events: newEvents,
            setRef: this.setRef.bind(this),
        };
    }
}

export default FieldContainer;
