/* eslint-disable import/prefer-default-export */

import { RegistrationFormActionType, UpdateRegistrationFormAction } from './RegistrationForm.type';

/**
 * Send message
 * @param {Object} data
 * @namespace Steinkrueger/Store/RegistrationForm/Action/updateRegistrationForm */
export const updateRegistrationForm = (data: { isLoading: boolean }): UpdateRegistrationFormAction => ({
    type: RegistrationFormActionType.UPDATE_REGISTRATION_FORM,
    data,
});
