import {PureComponent} from 'react';
import {connect} from 'react-redux';

import HomePageCustomerWelcomeComponent from 'Component/HomePageCustomerWelcome/HomePageCustomerWelcome.component';
import {
    HomePageCustomerWelcomeTypeContainerProps,
    HomePageCustomerWelcomeTypeContainerState,
} from 'Component/HomePageCustomerWelcome/HomePageCustomerWelcome.type';
import MyAccountQuery from 'Query/MyAccount.query';
import {ReactElement} from 'Type/Common.type';
import {fetchQuery} from 'Util/Request/Query';

/** @namespace Steinkrueger/Component/HomePageCustomerWelcome/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Steinkrueger/Component/HomePageCustomerWelcome/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Steinkrueger/Component/HomePageCustomerWelcome/Container */
export class HomePageCustomerWelcomeContainer extends PureComponent<
HomePageCustomerWelcomeTypeContainerProps,
HomePageCustomerWelcomeTypeContainerState> {
    __construct(): void {
        this.state = {
            customer: {
                firstname: '',
                lastname: '',
            },
            isLoading: true,
        };
    }

    componentDidMount() {
        this.getCustomerName();
    }

    async getCustomerName(): Promise<void> {
        try {
            const { customer } = await fetchQuery(MyAccountQuery.getCustomerQuery());
            const customerState = {
                firstname: customer.firstname,
                lastname: customer.lastname,
            };

            this.setState({
                customer: customerState,
                isLoading: false,
            });
        } catch (e) {
            this.setState({
                isLoading: false,
            });
        }
    }

    containerProps() {
        const {customer, isLoading} = this.state;

        return {
            customer,
            isLoading,
        };
    }

    render(): ReactElement {
        return (
            <HomePageCustomerWelcomeComponent { ...this.containerProps() } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageCustomerWelcomeContainer);
