import { connect } from 'react-redux';
import {Dispatch} from 'redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import {RootState} from 'Util/Store/Store.type';

/** @namespace Steinkrueger/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Steinkrueger/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Steinkrueger/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    containerProps() {
        const {
            isBreadcrumbsActive,
            isLoading,
            cmsPage,
            isHomePage = false,
            isSignedIn,
        } = this.props;

        return {
            isBreadcrumbsActive,
            isLoading,
            cmsPage,
            isHomePage,
            isSignedIn,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
