import {
    InstallPromptComponent as SourceInstallPromptComponent,
} from 'SourceComponent/InstallPrompt/InstallPrompt.component';

/** @namespace Steinkrueger/Component/InstallPrompt/Component */
export class InstallPromptComponent extends SourceInstallPromptComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hasSupport():boolean {
        const { device, hasInstallPromptEvent, isBannerClosed } = this.props;
        const {
            android,
            ios,
            standaloneMode,
        } = device;
        const isAndroid = android && hasInstallPromptEvent;

        return (isAndroid || ios)
            && !standaloneMode
            && !isBannerClosed;
    }
}

export default InstallPromptComponent;
