import {
    InstallPromptIOSComponent as SourceInstallPromptIOSComponent,
} from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';
import {ReactElement} from 'Type/Common.type';

import './InstallPromptIOS.override.style';

/** @namespace Steinkrueger/Component/InstallPromptIOS/Component */
export class InstallPromptIOSComponent extends SourceInstallPromptIOSComponent {
    renderContent(): ReactElement {
        return (
            <p block="InstallPromptIOS" elem="Content">
                <p block="InstallPromptIOS" elem="ContentRow">
                    <span>{ __('Tap:') }</span>
                    <span block="InstallPromptIOS" elem="Share" />
                    <span>{ __(', then') }</span>
                    <span block="InstallPromptIOS" elem="Plus" />
                </p>
                <p block="InstallPromptIOS" elem="ContentRow">
                    <span>{ __('Add to Home Screen') }</span>
                </p>
            </p>
        );
    }
}

export default InstallPromptIOSComponent;
