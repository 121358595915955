import { connect } from 'react-redux';

import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Steinkrueger/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    containerProps() {
        const {
            changeHeaderState,
            currentUrl,
            match,
            identifier,
        } = this.props;

        const isHomePage = true;
        const pageId = identifier ?? 'home';

        return {
            changeHeaderState,
            currentUrl,
            match,
            pageIdentifiers: pageId,
            isHomePage,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
