import CategoriesCmsBlock from 'Component/CategoriesCmsBlock';
import HomePageCustomerWelcome from 'Component/HomePageCustomerWelcome';
import Html from 'Component/Html';
import LatestBlogPostsCmsBlock from 'Component/LatestBlogPostsCmsBlock';
import RecommendedCmsBlock from 'Component/RecommendedCmsBlock';
import RegistrationForm from 'Component/RegistrationForm';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    CmsPageComponent as SourceCmsPageComponent,
} from 'SourceRoute/CmsPage/CmsPage.component';
import {ReactElement} from 'Type/Common.type';

import './CmsPage.override.style';

/** @namespace Steinkrueger/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    renderHeading(): ReactElement {
        const { cmsPage: { content_heading }, isLoading, isHomePage } = this.props;

        if (!content_heading || isLoading || isHomePage) {
            return null;
        }

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={ content_heading } />
            </h1>
        );
    }

    renderHomeForPageLoggedIn(): ReactElement {
        return (
            <div block="HomePageCustomer">
                <HomePageCustomerWelcome />
                <RecommendedCmsBlock identifier="recommended_products" />
                <CategoriesCmsBlock identifier="customer_home_page_categories" />
                <LatestBlogPostsCmsBlock identifier="latest_posts" />
            </div>
        );
    }

    renderContent(): ReactElement {
        const {
            isLoading,
            cmsPage: { content },
            isHomePage,
            isSignedIn,
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (isHomePage && isSignedIn) {
            const pageMessage = 'Sign In user';

            return (
                <>
                    { this.renderHomeForPageLoggedIn() }
                    <div block="PageMessage" elem="SignIn">
                        <Html content={ pageMessage } />
                    </div>
                </>
            );
        }

        if (!content) {
            return null;
        }

        return (
            <>
                <Html content={ content } />
                { isHomePage && <RegistrationForm /> }
            </>
        );
    }
}

export default CmsPageComponent;
