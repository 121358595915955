import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';

/** @namespace Steinkrueger/Component/RegistrationForm/Postcode/registrationFormPostcode */
export const registrationFormPostcode = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.TEXT,
        label: __('Postcode'),
        attr: {
            name: 'postcode',
            placeholder: __('Postcode'),
        },
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.TEXT,
        label: __('City'),
        attr: {
            name: 'city',
            placeholder: __('City'),
        },
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
];

export default registrationFormPostcode;
