import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { ValidationInputType } from 'Util/Validator/Config';

/**
 * Form for contacts
 * @namespace Steinkrueger/Component/RegistrationForm/Form/registrationForm */
export const registrationForm = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.TEXT,
        label: __('First name'),
        attr: {
            name: 'firstname',
            placeholder: __('Your first name'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.TEXT,
        label: __('Last name'),
        attr: {
            name: 'lastname',
            placeholder: __('Your last name'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.TEXT,
        label: __('Phone number'),
        attr: {
            name: 'phone_number',
            placeholder: __('Phone number'),
        },
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.EMAIL,
        label: __('Email address'),
        attr: {
            name: 'email',
            placeholder: 'ichliebekaese@mail.com',
        },
        addRequiredTag: true,
        validateOn: ['onBlur', 'onChange'],
        validationRule: {
            inputType: ValidationInputType.EMAIL,
            isRequired: true,
        },
    },
];

export default registrationForm;
