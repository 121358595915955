import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';

/** @namespace Steinkrueger/Component/RegistrationForm/Address/registrationFormAddress */
export const registrationFormAddress = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.TEXT,
        label: __('Street and house number'),
        attr: {
            name: 'address',
            placeholder: __('Street and house number'),
        },
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
];

export default registrationFormAddress;
