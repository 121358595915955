/* eslint-disable react/forbid-elements */

import {InputHTMLAttributes} from 'react';

import {FieldEvents, FieldNumberCustomEvents, FieldReactEvents} from 'Component/Field/Field.type';
import {FieldNumberWithControlsContainer} from 'Component/FieldNumberWithControls/FieldNumberWithControls.container';
import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';
import {ReactElement} from 'Type/Common.type';
import {noopFn} from 'Util/Common';

/** @namespace Steinkrueger/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    renderCheckboxOrRadio(): ReactElement {
        const {
            type,
            setRef,
            attr: { ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
        } = this.props;

        const {
            id = '',
            value = '',
        } = newAttr as InputHTMLAttributes<HTMLInputElement>;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!String(value).match('none') && isDisabled);
        const isRequired = true;
        const renderedLabel = label === 'Agreement' ? (
            <p>
                { __('I have read the Privacy Policy note. I agree that my information and data will'
                    + ' be collected and stored electronically to answer my request. Note: You can revoke your'
                    + ' consent at any time in the future by sending an email to ') }
                <a href="mailto:info@steinkrüger.de">info@steinkrüger.de. </a>
                { __('withdraw') }
                <span block="Field" elem="Label" mods={ { isRequired } }> *</span>
            </p>
        ) : label;

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr as InputHTMLAttributes<HTMLInputElement> }
                  { ...inputEvents as FieldReactEvents<HTMLInputElement> }
                />
                <div block="input-control" mods={ { disabled: isDisabled } } />
                { renderedLabel }
            </label>
        );
    }

    renderNumberWithControls(): ReactElement {
        const {
            attr,
            events,
            setRef,
            value,
            isDisabled = false,
            isKg,
            priceType,
        } = this.props;

        return (
            <FieldNumberWithControlsContainer
              isKg={ isKg }
              value={ value }
              attr={ attr as InputHTMLAttributes<HTMLInputElement> }
              events={ events as Omit<FieldEvents, 'onChange'> & FieldNumberCustomEvents }
              setRef={ setRef }
              isDisabled={ isDisabled }
              priceType={ priceType }
            />
        );
    }
}

export default FieldComponent;
