import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';

/**
 * Form for contacts
 * @namespace Steinkrueger/Component/RegistrationForm/Common/registrationFormCommon */
export const registrationFormCommon = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.TEXTAREA,
        label: __('News'),
        attr: {
            name: 'message',
            placeholder: __('Your message'),
        },
        addRequiredTag: false,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: false,
        },
    },
    {
        type: FieldType.FILE,
        label: __('Attach a file'),
        attr: {
            name: 'file',
            id: 'attachedFile',
            placeholder: __('Attach a file'),
        },
        validateOn: ['onChange'],
        validationRule: {
            fileExtension: {
                accept: 'jpg, jpeg, png, pdf',
            },
        },
    },
    {
        type: FieldType.CHECKBOX,
        // eslint-disable-next-line max-len
        label: 'Agreement',
        attr: {
            name: 'agreement',
            defaultChecked: false,
        },
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
];

export default registrationFormCommon;
