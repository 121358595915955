/* eslint-disable max-len */

import { PureComponent } from 'react';

import {SortSelectIconComponentProps} from 'Component/SortSelectIcon/SortSelectIcon.type';
import { ReactElement } from 'Type/Common.type';

import './SortSelectIcon.style.scss';

/** @namespace Steinkrueger/Component/SortSelectIcon/Component */
export class SortSelectIconComponent extends PureComponent<SortSelectIconComponentProps> {
    static defaultProps: Partial<SortSelectIconComponentProps> = {
        isSelected: false,
    };

    render(): ReactElement {
        const { isSelected } = this.props;

        if (isSelected) {
            return (
                <svg block="SortSelectIcon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="19" height="19" rx="9.5" stroke="#EE7F00" strokeWidth="2" />
                    <circle cx="10.5" cy="10.5" r="6.5" fill="#003E90" />
                </svg>
            );
        }

        return (
            <svg block="SortSelectIcon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="19" height="19" rx="9.5" stroke="#003E90" strokeWidth="2" />
            </svg>
        );
    }
}

export default SortSelectIconComponent;
