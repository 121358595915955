import { AnyAction } from 'redux';

import {GQLRegistrationForm} from 'Type/Graphql.registration.type';

export enum RegistrationFormActionType {
    UPDATE_REGISTRATION_FORM = 'UPDATE_REGISTRATION_FORM',
}

export interface UpdateRegistrationFormAction extends AnyAction {
    type: RegistrationFormActionType.UPDATE_REGISTRATION_FORM;
    data: {
        isLoading: boolean;
    };
}

export interface RegistrationFormStore {
    isLoading: boolean;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        ContactFormReducer: RegistrationFormStore;
    }
}

export interface RegistrationFormDispatcherOptions {
    form: {
        reset?: () => void;
    };
    fields: GQLRegistrationForm;
}
