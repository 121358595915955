import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import {Country} from 'Query/Region.type';

/** @namespace Steinkrueger/Component/RegistrationForm/Company/registrationFormCompany */
// eslint-disable-next-line max-len
export const registrationFormCompany = (availableRegions: Country[], selectedCountry: string): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.TEXT,
        label: __('Name of the institution'),
        attr: {
            name: 'company',
            placeholder: __('Name of the institution'),
        },
        addRequiredTag: true,
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
    },
    {
        type: FieldType.SELECT,
        label: __('Choose country'),
        attr: {
            name: 'country',
            selectPlaceholder: __('Choose country...'),
            defaultValue: selectedCountry,
        },
        options: availableRegions ? availableRegions.map(({ id, label }) => ({ id, label, value: id })) : [],
        validateOn: ['onChange'],
        addRequiredTag: true,
        validationRule: {
            isRequired: true,
        },
    },
];

export default registrationFormCompany;
