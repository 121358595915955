import {PureComponent} from 'react';

import {
    HomePageCustomerWelcomeTypeComponentProps,
} from 'Component/HomePageCustomerWelcome/HomePageCustomerWelcome.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import {TextPlaceHolderLength} from 'Component/TextPlaceholder/TextPlaceholder.config';
import {ReactElement} from 'Type/Common.type';

import './HomePageCustomerWelcome.style.scss';

/** @namespace Steinkrueger/Component/HomePageCustomerWelcome/Component */
export class HomePageCustomerWelcomeComponent extends PureComponent<HomePageCustomerWelcomeTypeComponentProps> {
    render(): ReactElement {
        const {customer: {firstname}, isLoading } = this.props;

        if (isLoading) {
            return (
                <div block="HomePageCustomerWelcome">
                    <div block="ContentWrapper">
                        <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                    </div>
                </div>
            );
        }

        return (
            <div block="HomePageCustomerWelcome">
                <div block="ContentWrapper">
                    <h1 block="HomePageCustomerWelcome" elem="Title">
                        <p>{ __('Good day,') }</p>
                        <p>{ `${firstname}!` }</p>
                    </h1>
                </div>
            </div>

        );
    }
}

export default HomePageCustomerWelcomeComponent;
