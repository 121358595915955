/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import ContactCmsBlock from 'Component/ContactCmsBlock';
import Field from 'Component/Field';
import { FieldContainerProps } from 'Component/Field/Field.type';
import FieldForm from 'Component/FieldForm';
import {FormSection} from 'Component/FieldForm/FieldForm.type';
import FieldGroup from 'Component/FieldGroup';
import { FormContainerProps } from 'Component/Form/Form.type';
import Loader from 'Component/Loader';
import registrationFormAddress from 'Component/RegistrationForm/RegistrationForm.address';
import registrationFormCommon from 'Component/RegistrationForm/RegistrationForm.common';
import registrationFormPostcode from 'Component/RegistrationForm/RegistrationForm.postcode';
import { ReactElement } from 'Type/Common.type';

import registrationFormCompany from './RegistrationForm.company';
import registrationForm from './RegistrationForm.form';
import { RegistrationFormComponentProps } from './RegistrationForm.type';

import './RegistrationForm.style';

/** @namespace Steinkrueger/Component/RegistrationForm/Component */
export class RegistrationFormComponent extends FieldForm<RegistrationFormComponentProps> {
    static defaultProps: Partial<RegistrationFormComponentProps> = {
        isLoading: false,
    };

    fieldMap(): Partial<FieldContainerProps>[] {
        return registrationForm();
    }

    fieldMapCompany(): Partial<FieldContainerProps>[] {
        const { countries } = this.props;
        const selectedCountry = 'DE';

        return registrationFormCompany(countries, selectedCountry);
    }

    fieldMapPostcode(): Partial<FieldContainerProps>[] {
        return registrationFormPostcode();
    }

    fieldMapAddress(): Partial<FieldContainerProps>[] {
        return registrationFormAddress();
    }

    fieldMapCommon(): Partial<FieldContainerProps>[] {
        return registrationFormCommon();
    }

    getFormProps(): Partial<FormContainerProps> {
        const { onFormSubmit } = this.props;

        return {
            onSubmit: onFormSubmit,
        };
    }

    renderFormBody(): ReactElement {
        const person = true;
        const company = true;
        const common = true;

        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="FieldsWrapper">
                    <div block="FieldForm" elem="Fields" mods={ {person} }>
                        <h3 block="FieldForm" elem="FieldsTitle">
                            { __('Contact Person') }
                        </h3>
                        { this.fieldMap().map(this.renderSection.bind(this)) }
                    </div>
                    <div block="FieldForm" elem="Fields" mods={ {company} }>
                        <h3 block="FieldForm" elem="FieldsTitle">
                            { __('Company') }
                        </h3>
                        { this.fieldMapCompany().map(this.renderSection.bind(this)) }
                        { this.fieldMapAddress().map(this.renderSection.bind(this)) }
                        <div block="FieldForm" elem="FieldsGroup">
                            { this.fieldMapPostcode().map(this.renderSection.bind(this)) }
                        </div>
                    </div>
                </div>
                <div block="FieldForm" elem="FieldsWrapper" mods={ {common} }>
                    { this.fieldMapCommon().map(this.renderSection.bind(this)) }
                </div>
                { this.renderActions() }
            </div>
        );
    }

    renderSection(section: FormSection | Partial<FieldContainerProps>): ReactElement {
        // If contains attr fields then outputs data as fields
        if ('fields' in section) {
            const { name: sectionName, attr: { name } = {}, fields } = section;

            return (
                <FieldGroup { ...section } key={ name || sectionName }>
                    { fields.map(this.renderSection.bind(this)) }
                </FieldGroup>
            );
        }
        const { attr: { name } = {} } = section;
        const { customer: { firstname } } = this.props;

        if (firstname && section.attr) {
            const sectionAttr = section.attr;
            const preselectedFields = name === 'firstname'
                || name === 'lastname'
                || name === 'middlename'
                || name === 'email';

            if (preselectedFields) {
                sectionAttr.defaultValue = this.props.customer[name];
            }

            return <Field { ...section } key={ name } />;
        }

        return <Field { ...section } key={ name } />;
    }

    renderTitle(): ReactElement {
        const {isLoading} = this.props;
        const contact_form_title = 'contact_form_title';

        return (
            <>
                <Loader isLoading={ isLoading } />
                <ContactCmsBlock identifier={ contact_form_title } />
            </>
        );
    }

    renderActions(): ReactElement {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <button type="submit" block="Button ArrowRight Button_Link">
                    { __('Send message') }
                </button>
            </>
        );
    }

    render(): ReactElement {
        const { isSignedIn } = this.props;
        const { pathname } = location;

        const isContactPage = pathname === '/contact';

        if (!isSignedIn || isContactPage) {
            return (
                <div block="RegistrationForm">
                    { this.renderTitle() }
                    { super.render() }
                </div>
            );
        }

        return null;
    }
}

export default RegistrationFormComponent;
