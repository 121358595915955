/* eslint-disable max-len */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    GQLRegistrationForm,
    RegistrationFormContainerState,
    RegistrationFormType,
} from 'Component/RegistrationForm/RegistrationForm.type';
import RegistrationFormDispatcher from 'Store/RegistrationForm/RegistrationForm.dispatcher';
import { ReactElement } from 'Type/Common.type';
import { FieldData } from 'Util/Form/Form.type';
import { RootState } from 'Util/Store/Store.type';

import RegistrationForm from './RegistrationForm.component';
import {
    RegistrationFormComponentContainerPropKeys,
    RegistrationFormComponentProps,
    RegistrationFormContainerFunctions,
    RegistrationFormContainerMapDispatchProps,
    RegistrationFormContainerMapStateProps,
    RegistrationFormContainerProps,
} from './RegistrationForm.type';

/** @namespace Steinkrueger/Component/RegistrationForm/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): RegistrationFormContainerMapStateProps => ({
    isLoading: state.ContactFormReducer.isLoading,
    countries: state.ConfigReducer.countries,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    customer: state.MyAccountReducer.customer,
});

/** @namespace Steinkrueger/Component/RegistrationForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): RegistrationFormContainerMapDispatchProps => ({
    sendMessage: (data) => RegistrationFormDispatcher.prepareRequest(data, dispatch),
});

/** @namespace Steinkrueger/Component/RegistrationForm/Container */
export class RegistrationFormContainer extends PureComponent<RegistrationFormContainerProps, RegistrationFormContainerState> {
    containerFunctions: RegistrationFormContainerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this),
    };

    __construct(): void {
        this.state = {
            customer: {},
        };
    }

    componentDidMount() {
        const { customer, isSignedIn } = this.props;

        this.setState({customer});

        if (isSignedIn && customer && !customer.firstname) {
            const customerFromLocalStorage = localStorage.getItem('customer');
            const formattedCustomerData = customerFromLocalStorage ? JSON.parse(customerFromLocalStorage) : {};
            this.setState({customer: formattedCustomerData.data});
        }

        if (!isSignedIn) {
            this.setState({ customer: {} });
        }
    }

    componentDidUpdate() {
        const { customer } = this.state;
        const { isSignedIn } = this.props;

        if (isSignedIn && customer && !customer.firstname) {
            const customerFromLocalStorage = localStorage.getItem('customer');
            const formattedCustomerData = customerFromLocalStorage ? JSON.parse(customerFromLocalStorage) : {data: {}};
            this.setState({customer: formattedCustomerData.data});
        }
    }

    onFormSubmit(form: HTMLFormElement, fields: FieldData[]): void {
        const { sendMessage } = this.props;
        const filteredFields: RegistrationFormType = {};

        fields.forEach(({ name, value }) => {
            filteredFields[name as keyof GQLRegistrationForm] = value ? String(value) : '';
        });

        const updatedFields = {
            firstname: filteredFields.firstname,
            lastname: filteredFields.lastname,
            phone_number: filteredFields.phone_number,
            email: filteredFields.email,
            company: filteredFields.company,
            country: filteredFields.country,
            postcode: filteredFields.postcode,
            city: filteredFields.city,
            address: filteredFields.address,
            message: filteredFields.message,
            file: {
                name: 'uploaded file',
                base64_encoded_file: filteredFields.file || '',
            },
        };

        sendMessage({ form, fields: updatedFields });
    }

    containerProps(): Pick<RegistrationFormComponentProps, RegistrationFormComponentContainerPropKeys> {
        const {
            isLoading, countries, isSignedIn,
        } = this.props;

        const { customer = {} } = this.state;

        return {
            isLoading, countries, isSignedIn, customer,
        };
    }

    render(): ReactElement {
        return (
            <RegistrationForm
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormContainer);
