import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import {CmsBlockComponentProps, CmsBlockContainerProps, CmsBlockContainerState} from 'Component/CmsBlock/CmsBlock.type';
import CmsBlockQuery from 'Query/CmsBlock.query';
import {CmsBlockQueryOutput} from 'Query/CmsBlock.type';
import {ReactElement} from 'Type/Common.type';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace Steinkrueger/Component/ContactCmsBlock/Container */
export class ContactCmsBlockContainer extends DataContainer<CmsBlockContainerProps, CmsBlockContainerState> {
    static defaultProps: Partial<CmsBlockContainerProps> = {
        blockType: '',
    };

    state: CmsBlockContainerState = {
        cmsBlock: {},
    };

    __construct(props: CmsBlockContainerProps): void {
        super.__construct(props, 'ContactCmsBlockContainer');
    }

    containerProps(): CmsBlockComponentProps {
        const { blockType, children } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, children };
    }

    componentDidMount(): void {
        this._getCmsBlock();
    }

    _getCmsBlock(): void {
        const { identifier } = this.props;

        this.fetchData<CmsBlockQueryOutput>(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            },
        );
    }

    render(): ReactElement {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default ContactCmsBlockContainer;
